<template>
  <component
    :is="tileImports[tile.typeHandle] ?? null"
    v-for="(tile, i) in tiles as BaseTileData[]"
    :key="tile.id"
    :ref="(el: any) => updateItemRefs(el)"
    :tile-data="tile as any"
    :size="getSizeByGridType(gridType, i)"
    :is-single-tile="tiles.length < 3"
    :query-args="queryArgs"
    :display-variant-name="displayVariantName"
    class="tile-item"
  />
</template>
<script setup lang="ts">
import type { LocationQueryRaw } from 'vue-router';
import { ETileGridType } from '~~/src/@types/tile-grid-type';
import { tileImports } from './components/tiles/dynamicTileImportMap';
import type { BaseTileData } from './types';
import { useTiles } from './useTiles';
// The tile-item class is for playwright, please dont remove it :)

defineProps({
  tiles: {
    type: Object as PropType<BaseTileData[]>,
    required: true,
  },
  queryArgs: {
    type: Object as PropType<LocationQueryRaw>,
    required: false,
    default: null,
  },
  gridType: {
    type: String as PropType<ETileGridType>,
    default: () => {
      return ETileGridType.UNSET;
    },
    required: false,
  },
  displayVariantName: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { updateItemRefs, getSizeByGridType } = useTiles();
</script>
