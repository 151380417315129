import { TileTypeHandle } from '../../types';

export const tileImports = {
  [TileTypeHandle.AGREEMENT]: defineAsyncComponent(
    () => import('./AgreementTile/AgreementTile.vue'),
  ),
  [TileTypeHandle.EVENT]: defineAsyncComponent(
    () => import('./EventTile/EventTile.vue'),
  ),
  [TileTypeHandle.OFFICE]: defineAsyncComponent(
    () => import('./OfficeTile/OfficeTile.vue'),
  ),
  [TileTypeHandle.SERVICE]: defineAsyncComponent(
    () => import('./ServiceTile/ServiceTile.vue'),
  ),
  [TileTypeHandle.OFFER]: defineAsyncComponent(
    () => import('./OfferTile/OfferTile.vue'),
  ),
  [TileTypeHandle.PERSON]: defineAsyncComponent(
    () => import('./PersonTile/PersonTile.vue'),
  ),
  [TileTypeHandle.POST]: defineAsyncComponent(
    () => import('./PostTile/PostTile.vue'),
  ),
  [TileTypeHandle.PRODUCT]: defineAsyncComponent(
    () => import('./ProductTile/product-tile.vue'),
  ),
  [TileTypeHandle.CAMPAIGN]: defineAsyncComponent(
    () => import('./CampaignTile/campaignTile.vue'),
  ),
};
